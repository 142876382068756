<template>
    <v-row no-gutters class="d-block">
        <v-col cols="12">
            <v-card>

                <v-card-text>
                    <v-form ref="form" v-model="valid">
                        <v-row>

                            <v-col cols="12" sm="12" md="12" lg="12" xl="12">

                                <v-alert dense type="warning" outlined class="text-center mt-3">
                                    Selecciona las Páginas y Acciones para asignarle permisos y pulsa en Guardar
                                </v-alert>

                                <v-row v-for="(pag) in paginasAccionesUsuarios" :key="pag.cat_pagina_id" class="">
                                    <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                        <v-divider class="mb-3"></v-divider>
                                        <v-icon>
                                            {{ pag.cat_pagina_icono }}
                                        </v-icon>
                                        <b class="ml-2">{{ pag.cat_pagina_nombre }}</b>
                                    </v-col>

                                    <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="ml-3 py-0">
                                        <v-row>
                                            <v-col cols="6" sm="4" md="3" lg="2" xl="2" class="py-0"
                                                   v-for="(ac) in pag.acciones "
                                                   :key="ac.pagina_accion_id">
                                                <v-checkbox class="d-inline-flex"
                                                            v-model="ac.activo"
                                                            :value="ac.activo"
                                                            :append-icon="ac.cat_accion_icono"
                                                            :label="ac.cat_accion_nombre"
                                                            hide-details
                                                ></v-checkbox>
                                            </v-col>
                                        </v-row>
                                    </v-col>

                                    <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="ml-3" v-show="pag.hijos">
                                        <v-row v-for="(hij) in pag.hijos " :key="hij.cat_pagina_id">
                                            <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                                <v-divider class="mb-3 mr-3"></v-divider>
                                                <v-icon>
                                                    {{ hij.cat_pagina_icono }}
                                                </v-icon>
                                                <b class="ml-2">{{ hij.cat_pagina_nombre }}</b>
                                            </v-col>

                                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="ml-3 py-0">
                                                <v-row>
                                                    <v-col cols="6" sm="4" md="3" lg="2" xl="2" class="py-0"
                                                           v-for="(hiac) in hij.acciones "
                                                           :key="hiac.pagina_accion_id">
                                                        <v-checkbox class="d-inline-flex"
                                                                    v-model="hiac.activo"
                                                                    :value="hiac.activo"
                                                                    :append-icon="hiac.cat_accion_icono"
                                                                    :label="hiac.cat_accion_nombre"
                                                                    hide-details
                                                        ></v-checkbox>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-col>

                                </v-row>

                            </v-col>

                        </v-row>

                    </v-form>
                </v-card-text>
                <v-divider class="mt-1"></v-divider>
                <v-card-actions class="justify-center py-3">
                    <v-btn outlined @click="regresar" small>
                        <v-icon>arrow_back</v-icon>
                        Regresar
                    </v-btn>

                    <v-btn outlined color="warning" @click="reset" small>
                        <v-icon>restart_alt</v-icon>
                        Limpiar
                    </v-btn>

                    <v-btn color="success" :loading="loading" @click="savePermiso" small>
                        <v-icon>save</v-icon>
                        Guardar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>

export default {
    name: "ParticipantesAdministradoresPermisos",
    props: {
        // Si es requerido falso, que sea Default Vacio
        getParams: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            valid: true,
            loading: false,
            paginasAccionesUsuarios: [],
            permisoModel: {
                persona_id: this.getParams.persona_id,
            },
            usuarioForm: {},
        }
    },
    computed: {
        // DO some
    },
    methods: {
        regresar() {
            this.$router.go(-1)
        },
        reset() {
            // Reset los valores del usuario
            this.getUsuarioCreate();
        },
        getUsuarioCreate() {
            this.loading = true;

            // Get data;
            let params = {persona_id: this.getParams.persona_id}

            window.axios.get('/paginas_acciones_usuarios', {params}).then(response => {
                if (response.data.success) {

                    this.paginasAccionesUsuarios = response.data.data;

                } else if (response.data.success === false) {
                    this.$toast.error(response.data.message);
                }
            })
                .catch(error => {
                    console.log(error)
                    this.$toast.error("No se pudo obtener los datos para guardar.");
                })
                .finally(() => {
                    // Do some
                    this.loading = false;
                })
        },
        savePermiso() {
            // Validar
            if (this.$refs.form.validate()) {
                this.loading = true;
                let url = '/paginas_acciones_usuarios/save';
                let params = {permisos: this.paginasAccionesUsuarios}

                window.axios.post(url, params).then(response => {
                    if (response.data.success) {
                        this.$toast.success(response.data.message);

                        // Mandar a traer los datos guardados
                        this.getUsuarioCreate();

                    } else if (response.data.success === false) {
                        this.$toast.error(response.data.message);
                    }
                })
                    .catch(error => {
                        console.log(error)
                        this.$toast.error("No se pudo guardar");
                    })
                    .finally(() => {
                        // Do some
                        this.loading = false;
                    })
            } else {
                this.$toast.warning("Complete toda la información requerida.");
            }

        },

    },
    created() {
        this.getUsuarioCreate();
    }
}
</script>

<style>

</style>
